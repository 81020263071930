import { Toolbar } from "@mui/material";
import Head from "next/head";
import { Fragment } from "react";
import ToResolveClassNameError from "../../components/hoc/ToResolveClassNameError";
import BacklinkComponent from "../../components/jobs/FooterWriteup/FooterLinks";
import HiredlyWriteup from "../../components/jobs/FooterWriteup/HiredlyWriteup";
import JobListSubheader from "../../components/jobs/JobListSubheader/JobListSubheader";
import JobListWrapper from "../../components/jobs/JobListWrapper/JobListWrapper";
import SpecialisationWriteup from "../../components/jobs/SpecialisationWriteup/SpecialisationWriteup";
import SharedChat from "../../components/shared/SharedChat/SharedChat";
import SharedCollapsibleFooter from "../../components/shared/SharedCollapsibleFooter/SharedCollapsibleFooter";
import * as config from "../../config/config";
import { formJobQueryParam } from "../../functions/job";
import {
  check404,
  convertUrlToIds,
  encodePage,
  getSelectedTab,
  getTitle,
} from "../../helpers/filter_management";
import {
  getCmsLocations,
  getCmsSpecialisations,
  getFooterCompanyWriteup,
  getJobsWithoutRedux,
  getSpecialisationWithoutRedux,
  getStateRegionWithoutRedux,
} from "../../redux/actions/job_action";

function JobFiltersPage(props) {
  const { jobs, ssrFilterParams, ssrPageParams, currentTab, titleInfo } = props;

  let metaTitle =
    "New Job Vacancies in Malaysia - Search By Location, Specialisation & Job Type | Hiredly";
  let metaDescription =
    "Easy and fast job search on one of Malaysia's leading job portal. Find remote and work-from-home job vacancies for full-time and internship career opportunities in Malaysia.";
  let metaIndex;

  return (
    <Fragment>
      <Head>
        {/* <!-- Primary Meta Tags --> */}
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        {metaIndex && <meta name="robots" content="noindex, nofollow" />}

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" key={"ogType"} />
        <meta
          property="og:url"
          content="https://my.hiredly.com/jobs"
          key="ogUrl"
        />
        <meta property="og:title" content={metaTitle} key="ogTitle" />
        <meta
          property="og:description"
          content={metaDescription}
          key="ogDescription"
        />
        <meta
          property="og:image"
          content={
            config.assetDomain + "/images/hiredly/hiredly-meta-image-v1.png"
          }
          key="ogImage"
        />

        {/* <!-- Twitter --> */}
        <meta
          property="twitter:card"
          content="summary_large_image"
          key="twitterCard"
        />
        <meta
          property="twitter:url"
          content="https://my.hiredly.com/jobs"
          key="twitterUrl"
        />
        <meta property="twitter:title" content={metaTitle} key="twitterTitle" />
        <meta
          property="twitter:description"
          content={metaDescription}
          key="twitterDescription"
        />
        <meta
          property="twitter:image"
          content={
            config.assetDomain + "/images/hiredly/hiredly-meta-image-v1.png"
          }
          key="twitterImage"
        />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="canonical" href={props.pageUrl} />
      </Head>
      <ToResolveClassNameError>
        <Toolbar
          sx={{
            minHeight: "64px",
            "@media(max-width: 1279px)": {
              minHeight: "52px",
            },
            "@media(max-width: 640px)": {
              minHeight: "60px",
            },
          }}
        />
      </ToResolveClassNameError>
      <JobListSubheader titleInfo={titleInfo ?? null} />
      <JobListWrapper
        serverJobs={jobs}
        ssrFilterParams={ssrFilterParams}
        ssrPageParams={ssrPageParams}
        currentTab={currentTab}
      />

      {currentTab === "job-list" && (
        <>
          <BacklinkComponent
            cmsSpecialisations={props.cmsSpecialisations}
            cmsLocations={props.cmsLocations}
          />
          <SpecialisationWriteup specialisation={props.specialisation} />
          <HiredlyWriteup companyWriteup={props.companyWriteup} />
        </>
      )}
      <SharedCollapsibleFooter />
      {/* SSR component END */}
      <ToResolveClassNameError>
        <SharedChat />
      </ToResolveClassNameError>
    </Fragment>
  );
}

export async function getServerSideProps(context) {
  const { query, req } = context;

  const pageExist = check404(query?.filter);

  if (pageExist) {
    return {
      notFound: true,
    };
  }

  const specialisationList = await getSpecialisationWithoutRedux();
  const stateList = await getStateRegionWithoutRedux();

  const filterParams = convertUrlToIds(query, specialisationList, stateList);
  const currentPage = encodePage(query?.page);

  const currentTab = getSelectedTab(
    filterParams,
    currentPage,
    req.cookies.defaultFyp
  );

  const jobParams = formJobQueryParam(filterParams, false);

  const pagination = {
    first: 30,
    last: null,
    startCursor: currentPage ?? null,
    endCursor: null,
  };

  const jobs = await getJobsWithoutRedux(jobParams, pagination);
  const titleInfo = getTitle(filterParams, specialisationList);

  let cmsSpecialisations = [];
  let cmsLocations = [];
  let companyWriteup = {};

  if (currentTab === "job-list") {
    cmsSpecialisations = await getCmsSpecialisations();
    cmsLocations = await getCmsLocations();
    companyWriteup = await getFooterCompanyWriteup();
  }

  return {
    props: {
      currentTab: currentTab,
      jobs: jobs?.jobs ?? [],
      ssrFilterParams: filterParams,
      ssrPageParams: currentPage ?? null,
      titleInfo: titleInfo,
      cmsSpecialisations: cmsSpecialisations ?? [],
      cmsLocations: cmsLocations ?? [],
      companyWriteup: companyWriteup ?? {},
    },
  };
}

export default JobFiltersPage;
